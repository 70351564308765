<template>
  <div class="mt2 flex items-end flex-wrap justify-center funteamclues__give-clue">
    <div class="flex flex-column mr1 mb1">
      <label class="color-white">{{ $t('funTeamClues.clue') }}</label>
      <Input type="text" v-model="clue" />
    </div>
    <div class="flex flex-column mr1 mb1">
      <label class="color-white">{{ $t('funTeamClues.answers') }}</label>
      <Input type="number" min="1" :max="8" v-model="clueNumberOfGuesses" />
    </div>
    <div class="mb1">
      <Button class="p-button-white" @click="giveClue">{{ $t('funTeamClues.giveClue') }}</Button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FunTeamCluesGiveClue',
    data() {
      return {
        clue: '',
        clueNumberOfGuesses: ''
      };
    },
    methods: {
      giveClue() {
        this.clue &&
          this.$emit('give-clue', {
            clue: this.clue,
            clueNumberOfGuesses: Number(this.clueNumberOfGuesses)
          });
      }
    }
  };
</script>

<style scoped lang="scss">
  .funteamclues__give-clue {
    width: 100%;

    @include mobile() {
      input {
        max-width: 170px;
        font-size: 12px;
      }
    }
  }
</style>
