
  import { computed, ref, defineComponent } from 'vue';

  import useFirebase from '@/composables/use-firebase';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';
  import QuickInstructions from '@/components/common/QuickInstructions.vue';
  import BaseNewGameLinks from '@/components/common/BaseNewGameLinks.vue';
  import useToast from '@/composables/use-toast';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'PhaseWelcome',
    components: { BaseButtonWithSpinner, QuickInstructions, BaseNewGameLinks },
    props: {
      game: {
        type: Object,
        default: () => ({})
      },
      startGame: {
        type: Function,
        default: () => {}
      },
      cancelGame: {
        type: Function,
        default: () => {}
      },
      instructions: {
        type: Array,
        default: () => []
      },
      showStartGameButton: {
        type: Boolean,
        default: false
      }
    },
    setup(props: any) {
      const { getCurrentUser } = useFirebase();

      const user = getCurrentUser();
      const { successToast } = useToast();
      const { t } = useI18n();

      const startGameLoading = ref(false);
      const cancelGameLoading = ref(false);

      const isCurrentUserAdmin = computed(() => {
        const player = props.game.players.find((player: any) => player.id === user?.uid);
        return player?.admin;
      });

      const isCurrentUserGameCreator = computed(() => {
        return props.game.gameCreator.id === user?.uid;
      });

      const copy = (): void => {
        navigator.clipboard.writeText(window.location.href);
        successToast(t('common.copiedToClipboard'));
      };

      const handleStartGame = async () => {
        startGameLoading.value = true;
        await props.startGame();
        startGameLoading.value = false;
      };

      const handleCancelGame = async () => {
        cancelGameLoading.value = true;
        await props.cancelGame();
        successToast(t('common.gameCancelled'));
      };

      return {
        isCurrentUserAdmin,
        startGameLoading,
        cancelGameLoading,
        isCurrentUserGameCreator,
        user,
        handleStartGame,
        handleCancelGame,
        copy
      };
    }
  });
