<template>
  <div class="game-logout flex flex-column items-start">
    <span v-if="user?.email">{{ user?.email }}</span>
    <button class="mt1 button--small" @click="logout">
      {{ $t('adminPanel.navigation.logout') }}
    </button>
  </div>
</template>

<script>
  import useFirebase from '@/composables/use-firebase';

  export default {
    name: 'GameLogout',
    setup() {
      const { logout, getCurrentUser } = useFirebase();
      const user = getCurrentUser();

      return { logout, user };
    }
  };
</script>

<style scoped lang="scss">
  .game-logout {
    span {
      @include font(12px, 600);
    }

    @include mobile() {
      display: none;
    }
  }
</style>
