
  import { defineComponent, PropType, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';

  import useToast from '@/composables/use-toast';

  import useFirebaseFunTeamClues from '@/composables/use-firebase-funteamclues';
  import FunTeamCluesGiveClue from '@/components/funteamclues/FunTeamCluesGiveClue.vue';
  import { FunTeamCluesWord } from '@/interfaces/funteamclues';

  export default defineComponent({
    name: 'FunTeamCluesWords',
    components: {
      FunTeamCluesGiveClue
    },
    props: {
      words: {
        type: Array as PropType<FunTeamCluesWord[]>,
        default: () => []
      },
      phase: {
        type: String,
        default: ''
      },
      activeTeamName: {
        type: String,
        default: ''
      },
      clueNumberOfGuesses: {
        type: Number,
        default: 0
      },
      clue: {
        type: String,
        default: ''
      },
      gameEnded: {
        type: Boolean,
        default: false
      },
      lastClickedWord: {
        type: Object,
        default: () => null
      }
    },
    setup(props) {
      const { currentPlayerInTeam, giveClue, guessWord, checkWhichTeamWon } =
        useFirebaseFunTeamClues();
      const clueLoading = ref(false);
      const wordLoading = ref(false);
      const { warningToast, infoToast, errorToast, successToast } = useToast();
      const { t } = useI18n();

      const handleGiveClue = async ({
        clue,
        clueNumberOfGuesses
      }: {
        clue: string;
        clueNumberOfGuesses: number;
      }) => {
        if (!clue || !clueNumberOfGuesses) {
          warningToast(t('funTeamClues.notAllInfoProvided'));
          return;
        }

        if (isNaN(clueNumberOfGuesses)) {
          warningToast(t('funTeamClues.hasToBeNumber'));
          return;
        }

        if (clueNumberOfGuesses > 9) {
          warningToast(t('funTeamClues.maxNineGuess'));
          return;
        }

        clueLoading.value = true;
        await giveClue(clue, clueNumberOfGuesses);
        clueLoading.value = false;
      };

      const handleGuessWord = async (word: FunTeamCluesWord) => {
        wordLoading.value = true;
        await guessWord(word);
        wordLoading.value = false;
      };

      watch(
        () => props.lastClickedWord,
        (newVal, oldVal) => {
          if (oldVal?.label === newVal?.label) {
            return;
          }

          if (newVal.belongsTo === props.activeTeamName) {
            successToast(t('funTeamClues.congratsYouGuessed'));
          } else if (newVal.belongsTo === 'KILLER') {
            errorToast(
              t('funTeamClues.killerChosen', {
                activeTeamName: props.activeTeamName === 'RED' ? 'BLUE' : 'RED'
              }),
              4000,
              ''
            );
          } else if (!newVal.belongsTo) {
            infoToast(t('funTeamClues.neutralWord'));
          } else {
            warningToast(t('funTeamClues.wrongWord'));
          }
        }
      );

      watch(
        () => props.gameEnded,
        newVal => {
          if (!newVal) {
            return;
          }

          const winningTeamName = checkWhichTeamWon(props.words);

          if (winningTeamName) {
            successToast(t('funTeamClues.teamWins', { winningTeamName: winningTeamName }));
          }
        }
      );

      watch(
        () => props.activeTeamName,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            infoToast(
              t('funTeamClues.nextRound', {
                activeTeamName: props.activeTeamName === 'RED' ? 'BLUE' : 'RED'
              })
            );
          }
        }
      );

      return { handleGiveClue, currentPlayerInTeam, handleGuessWord };
    }
  });
