
  import { ref, computed, defineComponent, onMounted, Ref, ComputedRef } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  import { emptyFunTeamCluesPlayer, emptyFunTeamCluesGame } from '@/helpers/funteamclues/initials';
  import useFirebase from '@/composables/use-firebase';
  import useToast from '@/composables/use-toast';
  import useFirebaseFunTeamClues from '@/composables/use-firebase-funteamclues';
  import { GAME_NAMES, GAME_NAMES_HUMANIZED } from '@/helpers';
  import { useI18n } from 'vue-i18n';
  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import LogoSpinner from '@/components/common/LogoSpinner.vue';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'FunTeamCluesNewGame',
    components: { LanguageSelection, LogoSpinner },
    setup() {
      const { getCurrentUser, addAlertThatNewGameStarted } = useFirebase();
      const user = getCurrentUser();
      const { getCurrentCompany, getWords } = useFirebaseFunTeamClues();

      const { push } = useRouter();
      const store = useStore();
      const { successToast, warningToast, errorToast } = useToast();
      const { t, locale } = useI18n();

      const loading: Ref = ref(false);
      const gameId: Ref<string> = ref('');
      const newGameLoading: Ref<boolean> = ref(false);
      const shouldLimitTimeForGuessing: Ref<boolean> = ref(false);
      const shouldLimitTimeForGivingClue: Ref<boolean> = ref(false);
      const limitTimeForGuessing: Ref<string> = ref('');
      const limitTimeForGivingClue: Ref<string> = ref('');
      const name: Ref<string> = ref('');
      const nickname: Ref<string> = ref('');
      const creatorShouldPlayGame: Ref<boolean> = ref(true);

      const company: ComputedRef = computed(() => store.state.company);

      const userHasPermissionsToCreateGames: ComputedRef = computed(
        () => store.state.user?.roles?.gameCreator
      );

      const buttonDisabled: ComputedRef = computed(() => {
        if (creatorShouldPlayGame.value) {
          return !name.value || !userHasPermissionsToCreateGames.value;
        } else {
          return !userHasPermissionsToCreateGames.value;
        }
      });

      const getTeamForWord = (index: number, teamsRandomized: string[]) => {
        if (!index) {
          return 'KILLER';
        } else if (index < 10) {
          return teamsRandomized[0];
        } else if (index < 18) {
          return teamsRandomized[1];
        } else {
          return null;
        }
      };

      onMounted(async () => {
        loading.value = true;

        try {
          await getCurrentCompany();
          await getCurrentUserRoles();

          if (
            !store.state.company ||
            !store.state.company.package ||
            !store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_CLUES] ||
            store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_CLUES] === 0
          ) {
            errorToast(
              t('errors.noPackageOrGames', {
                name: GAME_NAMES_HUMANIZED.FUN_TEAM_CLUES
              })
            );
            return;
            // logic to help user buy new ones
          }
        } catch (e) {
          errorToast(e);
        } finally {
          loading.value = false;
        }
      });

      const getCurrentUserRoles = async () => {
        const response = await db
          .doc(`companies/${store.state.company.id}/users/${user?.email}`)
          .get()
          .then(doc => doc.data());

        if (!response?.roles?.gameCreator) {
          warningToast(t('warnings.noPermissionsToCreateGames'));
        }
        store.commit('setCurrentUserRoles', response?.roles);
      };

      const createNewGame = async (game: any) => {
        delete game.id;

        return await db
          .collection(`companies/${store.state.company.id}/funTeamCluesGames`)
          .add({
            ...game,
            createdOn: Date.now()
          })
          .then(docRef => {
            return docRef.get();
          })
          .then(doc => {
            return doc.id;
          });
      };

      const removeOneGameFromPackage = async () => {
        await db.doc(`companies/${store.state.company.id}`).update({
          package: {
            ...store.state.company.package,
            gamesLeft: {
              ...store.state.company.package.gamesLeft,
              [GAME_NAMES.FUN_TEAM_CLUES]:
                store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_CLUES] - 1
            }
          }
        });
      };

      const addGame = async (): Promise<any> => {
        if ([name.value, nickname.value].some(v => v.length > 20)) {
          warningToast(t('warnings.cantBeLongerThan', { value: 20 }));
          return;
        }

        if (newGameLoading.value) {
          return;
        }

        newGameLoading.value = true;

        // so that we have newest data
        await getCurrentCompany();

        const words = await getWords(locale.value);
        const randomIndexes = Array.from({ length: 25 }, (v, i) => i).sort(
          () => Math.random() - 0.5
        );

        const teamsRandomized = ['RED', 'BLUE'].sort(() => Math.random() - 0.5);

        const sortedWords = words
          .sort(() => Math.random() - 0.5)
          .slice(0, 25)
          .map((word: string, index: number) => ({
            id: index + 1,
            label: word,
            belongsTo: getTeamForWord(randomIndexes[index], teamsRandomized),
            selected: false,
            hasBetOnFrom: []
          }));

        if (
          !store.state.company ||
          !store.state.company.package ||
          !store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_CLUES] ||
          store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_CLUES] === 0
        ) {
          errorToast(
            t('errors.noPackageOrGames', {
              name: GAME_NAMES_HUMANIZED.FUN_TEAM_CLUES
            })
          );
          newGameLoading.value = false;
          return;
          // logic to help user buy new ones
        }

        const playerData = {
          ...emptyFunTeamCluesPlayer,
          name: name.value,
          nickname: nickname.value,
          email: user?.email || null,
          id: user?.uid,
          admin: true
        };

        const newDocument = {
          ...emptyFunTeamCluesGame,
          words: sortedWords,
          players: creatorShouldPlayGame.value ? [playerData] : [],
          gameCreator: playerData,
          activeTeamName: teamsRandomized[0],
          settings: {
            timeForGuessingInSeconds: limitTimeForGuessing.value ? limitTimeForGuessing : null,
            timeForGivingClueInSeconds: limitTimeForGivingClue.value ? limitTimeForGivingClue : null
          }
        };

        try {
          gameId.value = await createNewGame(newDocument);
          await removeOneGameFromPackage();
          await addAlertThatNewGameStarted(
            'FunTeamClues',
            store.state.company.id,
            store.state.company.name,
            gameId.value
          );
          successToast(t('success.gameCreated'));
          store.commit('toggleSound', true);
          newGameLoading.value = false;
          goToGame();
        } catch (e) {
          newGameLoading.value = false;
          errorToast(e);
        }
      };

      const goToGame = (): void => {
        push({
          name: 'funteamclues-game',
          params: { companyId: company.value.id, gameId: gameId.value }
        });
      };

      return {
        loading,
        name,
        user,
        newGameLoading,
        userHasPermissionsToCreateGames,
        company,
        nickname,
        creatorShouldPlayGame,
        shouldLimitTimeForGuessing,
        shouldLimitTimeForGivingClue,
        limitTimeForGuessing,
        limitTimeForGivingClue,
        buttonDisabled,
        addGame
      };
    }
  });
