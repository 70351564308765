
  import { defineComponent, PropType } from 'vue';

  import { FunTeamBiddingPlayer } from '@/interfaces/funteambidding';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';

  export default defineComponent({
    name: 'FunTeamCluesJoinGame',
    components: { BaseButtonWithSpinner },
    props: {
      players: {
        type: Array as PropType<FunTeamBiddingPlayer[]>,
        default: () => []
      },
      joinLoading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        name: '',
        nickname: ''
      };
    },
    computed: {
      nameTaken(): boolean {
        const names: string[] = this.players.map((p: FunTeamBiddingPlayer) => p.name);

        return names.includes(this.name);
      },
      someInputTooLong(): boolean {
        return [this.name, this.nickname].some(v => v.length > 20);
      }
    }
  });
