
  import { defineComponent, onMounted, ref, Ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';

  import FunTeamCluesJoinGame from '@/components/funteamclues/FunTeamCluesJoinGame.vue';
  import FunTeamCluesInstructionGuy from '@/components/funteamclues/FunTeamCluesInstructionGuy.vue';
  import PeopleStillJoining from '@/components/PeopleStillJoining.vue';

  import LogoSpinner from '@/components/common/LogoSpinner.vue';
  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import IconAttribute from '@/components/common/IconAttribute.vue';
  import SoundMuting from '@/components/SoundMuting.vue';
  import GameLogout from '@/components/GameLogout.vue';
  import FunTeamCluesPhaseNav from '@/components/funteamclues/FunTeamCluesPhaseNav.vue';
  import FunTeamCluesBoard from '@/components/funteamclues/FunTeamCluesBoard.vue';
  import PhaseWelcome from '@/components/common/PhaseWelcome.vue';

  import useFirebaseFunTeamClues from '@/composables/use-firebase-funteamclues';
  import useToast from '@/composables/use-toast';
  import { FUN_TEAM_CLUES_PHASES } from '@/helpers/funteamclues/initials';
  import useFirebase from '@/composables/use-firebase';

  export default defineComponent({
    name: 'FunTeamCluesGame',
    components: {
      GameLogout,
      IconAttribute,
      LanguageSelection,
      LogoSpinner,
      SoundMuting,
      FunTeamCluesJoinGame,
      FunTeamCluesInstructionGuy,
      PeopleStillJoining,
      FunTeamCluesPhaseNav,
      FunTeamCluesBoard,
      PhaseWelcome
    },
    data() {
      return {
        optionsVisible: false
      };
    },
    setup() {
      const gameLoading: Ref = ref(false);
      const joinLoading: Ref = ref(false);

      const { currentRoute } = useRouter();
      const store = useStore();
      const { successToast } = useToast();
      const { t, locale } = useI18n();
      const { getCurrentUser } = useFirebase();
      const user = getCurrentUser();
      const { push } = useRouter();

      const {
        getCurrentCompany,
        getOneFunTeamCluesGameAndListen,
        showJoinGame,
        spyFromCurrentTeam,
        joinGame,
        showStartGameButton,
        startGame,
        cancelGame,
        currentPlayer
      } = useFirebaseFunTeamClues();

      const instructions = computed(() => [
        t('funTeamClues.shortInstructions.0'),
        t('funTeamClues.shortInstructions.1'),
        t('funTeamClues.shortInstructions.2'),
        t('funTeamClues.shortInstructions.3')
      ]);

      const currentGame = computed(() => {
        return store.state.currentFunTeamCluesGame;
      });

      onMounted(async () => {
        await getCurrentCompany(currentRoute.value.params.companyId);

        // @ts-ignore
        await getOneFunTeamCluesGameAndListen(String(currentRoute.value.params.gameId));
        locale.value = store.state.company.signupLanguage || 'en';
        gameLoading.value = false;
      });

      const copy = (): void => {
        // @ts-ignore
        navigator.clipboard.writeText(
          `https://${window.location.hostname}/funteamclues/${currentRoute.value.params.companyId}/${currentRoute.value.params.gameId}`
        );
        successToast(t('common.copiedToClipboard'));
      };

      const handleJoinGame = async (data: any) => {
        joinLoading.value = true;
        await joinGame(data);
        joinLoading.value = false;
      };

      const startNewGame = () => {
        if (user?.email) {
          push({ name: 'funteamclues-new-game' });
        } else {
          push({ name: 'funteamclues-new-game-without-register' });
        }
      };

      return {
        currentGame,
        showJoinGame,
        gameLoading,
        showStartGameButton,
        FUN_TEAM_CLUES_PHASES,
        instructions,
        currentPlayer,
        spyFromCurrentTeam,
        joinLoading,
        startGame,
        handleJoinGame,
        cancelGame,
        copy,
        startNewGame
      };
    }
  });
