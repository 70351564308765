
  import { defineComponent, PropType } from 'vue';
  import { FunTeamCluesLogItem } from '@/interfaces/funteamclues';

  export default defineComponent({
    name: 'FunTeamCluesLog',
    props: {
      log: {
        type: Array as PropType<FunTeamCluesLogItem[]>,
        default: () => []
      }
    },
    data() {
      return {
        logOpen: false
      };
    }
  });
