<template>
  <div
    v-if="phase !== 'WELCOME'"
    class="flex items-center justify-between funteamclues__phase-nav"
    :class="{
      red: activeTeamName === 'RED',
      blue: activeTeamName === 'BLUE'
    }"
  >
    <div class="funteamclues__phase-nav-step">
      {{
        $t(`funTeamClues.phasesDescriptions.${FUN_TEAM_CLUES_PHASES[phase].step - 1}`, {
          spyName,
          activeTeamName
        })
      }}
    </div>
  </div>
</template>

<script>
  import { FUN_TEAM_CLUES_PHASES } from '@/helpers/funteamclues/initials';

  export default {
    name: 'FunTeamCluesPhaseNav',
    props: {
      phase: {
        type: String,
        default: ''
      },
      activeTeamName: {
        type: String,
        default: ''
      },
      spyName: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        FUN_TEAM_CLUES_PHASES
      };
    }
  };
</script>

<style scoped lang="scss">
  .funteamclues__phase-nav {
    padding: 12px 24px;
    background: white;
    box-shadow: global-vars(ui-default-box-shadow--light);
    border-radius: global-vars(ui-default-measure2x);

    @include mobile() {
      width: calc(100% - 40px);
      margin-right: 40px;
      padding: 6px 12px;
    }

    &.red {
      background: lightpink;
    }

    &.blue {
      background: lightblue;
    }

    &-step {
      @include font(14px, 600);

      @include lg-down() {
        @include font(13px, 600);
      }

      @include md-down() {
        @include font(10px, 600);
      }
    }
  }
</style>
