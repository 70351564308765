
  import { ref, defineComponent, onMounted, Ref } from 'vue';
  import firebase from 'firebase';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  import { emptyFunTeamCluesPlayer, emptyFunTeamCluesGame } from '@/helpers/funteamclues/initials';
  import useFirebase from '@/composables/use-firebase';
  import useToast from '@/composables/use-toast';
  import useFirebaseFunTeamClues from '@/composables/use-firebase-funteamclues';
  import { useI18n } from 'vue-i18n';
  import LanguageSelection from '@/components/common/LanguageSelection.vue';
  import LogoSpinner from '@/components/common/LogoSpinner.vue';

  const db = firebase.firestore();

  export default defineComponent({
    name: 'FunTeamCluesNewGame',
    components: { LanguageSelection, LogoSpinner },
    setup() {
      const { getCurrentUser, addAlertThatNewGameStarted } = useFirebase();
      const user = getCurrentUser();
      const { getWords } = useFirebaseFunTeamClues();

      const { push } = useRouter();
      const store = useStore();
      const { successToast, warningToast, errorToast } = useToast();
      const { t, locale } = useI18n();

      const loading: Ref = ref(false);
      const gameId: Ref<string> = ref('');
      const newGameLoading: Ref<boolean> = ref(false);
      const shouldLimitTimeForGuessing: Ref<boolean> = ref(false);
      const shouldLimitTimeForGivingClue: Ref<boolean> = ref(false);
      const limitTimeForGuessing: Ref<string> = ref('');
      const limitTimeForGivingClue: Ref<string> = ref('');
      const name: Ref<string> = ref('');
      const nickname: Ref<string> = ref('');
      const company: Ref = ref(store.state.company);

      const getTeamForWord = (index: number, teamsRandomized: string[]) => {
        if (!index) {
          return 'KILLER';
        } else if (index < 10) {
          return teamsRandomized[0];
        } else if (index < 18) {
          return teamsRandomized[1];
        } else {
          return null;
        }
      };

      const getNoRegisterCompany = async () => {
        company.value = await db
          .doc(`companies/${process.env.VUE_APP_FREE_COMPANY}`)
          .get()
          .then(doc => ({ id: doc.id, ...doc.data() }));
      };

      onMounted(async () => {
        loading.value = true;

        try {
          await getNoRegisterCompany();
        } catch (e) {
          errorToast(e);
        } finally {
          loading.value = false;
        }
      });

      const createNewGame = async (game: any) => {
        delete game.id;
        return await db
          .collection(`companies/${company.value.id}/funTeamCluesGames`)
          .add({
            ...game,
            createdOn: Date.now()
          })
          .then(docRef => {
            return docRef.get();
          })
          .then(doc => {
            return doc.id;
          });
      };

      const addGame = async (): Promise<any> => {
        if (!company.value.id) {
          errorToast(t('common.somethingWrong'));
          return;
        }

        if ([name.value, nickname.value].some(v => v.length > 20)) {
          warningToast(t('warnings.cantBeLongerThan', { value: 20 }));
          return;
        }

        if (newGameLoading.value) {
          return;
        }

        newGameLoading.value = true;

        if (!user?.uid) {
          await firebase.auth().signInAnonymously();
        }

        const newUser = getCurrentUser();

        const words = await getWords(locale.value);
        const randomIndexes = Array.from({ length: 25 }, (v, i) => i).sort(
          () => Math.random() - 0.5
        );

        const teamsRandomized = ['RED', 'BLUE'].sort(() => Math.random() - 0.5);

        const sortedWords = words
          .sort(() => Math.random() - 0.5)
          .slice(0, 25)
          .map((word: string, index: number) => ({
            id: index + 1,
            label: word,
            belongsTo: getTeamForWord(randomIndexes[index], teamsRandomized),
            selected: false,
            hasBetOnFrom: []
          }));

        const playerData = {
          ...emptyFunTeamCluesPlayer,
          name: name.value,
          nickname: nickname.value,
          email: newUser?.email || null,
          id: newUser?.uid,
          admin: true
        };

        const newDocument = {
          ...emptyFunTeamCluesGame,
          words: sortedWords,
          players: [playerData],
          gameCreator: playerData,
          activeTeamName: teamsRandomized[0],
          settings: {
            timeForGuessingInSeconds: limitTimeForGuessing.value ? limitTimeForGuessing : null,
            timeForGivingClueInSeconds: limitTimeForGivingClue.value ? limitTimeForGivingClue : null
          }
        };

        try {
          gameId.value = await createNewGame(newDocument);
          await addAlertThatNewGameStarted(
            'FunTeamClues',
            company.value.id,
            company.value.name,
            gameId.value
          );
          successToast(t('success.gameCreated'));
          store.commit('toggleSound', true);
          newGameLoading.value = false;
          goToGame();
        } catch (e) {
          newGameLoading.value = false;
          errorToast(e);
        }
      };

      const goToGame = (): void => {
        push({
          name: 'funteamclues-game',
          params: { companyId: company.value.id, gameId: gameId.value }
        });
      };

      return {
        loading,
        name,
        user,
        newGameLoading,
        company,
        nickname,
        shouldLimitTimeForGuessing,
        shouldLimitTimeForGivingClue,
        limitTimeForGuessing,
        limitTimeForGivingClue,
        addGame
      };
    }
  });
