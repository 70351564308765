<template>
  <div class="funteamclues__board flex justify-between items-start">
    <FunTeamCluesTeam
      :team="teamRed"
      :phase="game.phase"
      :players="game.players"
      :teams="game.teams"
      :active-team-name="game.activeTeamName"
    />
    <slot v-if="game.phase === 'WELCOME'"></slot>
    <FunTeamCluesWords
      v-else
      :words="game.words"
      :phase="game.phase"
      :active-team-name="game.activeTeamName"
      :clue-number-of-guesses="game.clueNumberOfGuesses"
      :clue="game.clue"
      :game-ended="game.gameEnded"
      :last-clicked-word="game.lastClickedWord"
    />
    <FunTeamCluesTeam
      :team="teamBlue"
      :phase="game.phase"
      :players="game.players"
      :teams="game.teams"
      :active-team-name="game.activeTeamName"
    />
    <FunTeamCluesLog :log="game.log" />
  </div>
</template>

<script>
  import FunTeamCluesTeam from '@/components/funteamclues/FunTeamCluesTeam.vue';
  import FunTeamCluesWords from '@/components/funteamclues/FunTeamCluesWords.vue';
  import FunTeamCluesLog from '@/components/funteamclues/FunTeamCluesLog.vue';

  export default {
    name: 'FunTeamCluesBoard',
    components: {
      FunTeamCluesTeam,
      FunTeamCluesLog,
      FunTeamCluesWords
    },
    props: {
      game: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      teamRed() {
        return this.game.teams.find(team => team.name === 'RED');
      },
      teamBlue() {
        return this.game.teams.find(team => team.name === 'BLUE');
      }
    },
    methods: {}
  };
</script>

<style scoped lang="scss">
  .funteamclues__board {
    @include mobile() {
      flex-wrap: wrap;
    }

    &-item {
      height: 10vh;
      width: 10vw;
      padding: 2vh 2vw;
      margin: 0.5vh 0.5vw;
      background: white;
      border-radius: 1vh;
      @include font(1vmax, 600);
      text-transform: uppercase;

      &--red {
        background: lightpink;

        &.selected {
          border: 4px solid red;
        }
      }

      &--blue {
        background: lightskyblue;

        &.selected {
          border: 4px solid blue;
        }
      }

      &--killer {
        background: lightgray;

        &.selected {
          border: 4px solid gray;
        }
      }
    }
  }
</style>
