<template>
  <div class="flex flex-column items-center">
    <Button class="base-new-game-link" @click="$emit('copy')">
      {{ $t('newGame.copyLink') }}
    </Button>
    <a href="https://meet.google.com/new" target="_blank" class="mb1">
      <Button class="base-new-game-link">
        {{ $t('newGame.generateGoogleMeet') }}
      </Button>
    </a>
    <div
      v-if="(isCurrentUserGameCreator || isCurrentUserAdmin) && isUserAnOrganisationUser"
      class="mb2"
    >
      <BaseButtonWithSpinner @click="$emit('cancel')" :loading="cancelLoading">
        {{ $t('common.cancelGame') }}
      </BaseButtonWithSpinner>
    </div>
  </div>
</template>

<script>
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';

  export default {
    name: 'BaseNewGameLinks',
    props: {
      cancelLoading: {
        type: Boolean,
        default: false
      },
      isCurrentUserGameCreator: {
        type: Boolean,
        default: false
      },
      isCurrentUserAdmin: {
        type: Boolean,
        default: false
      },
      isUserAnOrganisationUser: {
        type: Boolean,
        default: false
      }
    },
    components: { BaseButtonWithSpinner },
    emits: ['cancel', 'copy']
  };
</script>

<style scoped lang="scss">
  .base-new-game-link {
    margin-top: global-vars(ui-default-measure2x);

    @include mobile() {
      margin-top: global-vars(ui-default-measure-half);
    }
  }
</style>
