import { useStore } from 'vuex';
import firebase from 'firebase';
import { computed, ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import useToast from './use-toast';
import { useI18n } from 'vue-i18n';

import { emptyFunTeamCluesPlayer } from '@/helpers/funteamclues/initials';
import { Avatar, Player } from '@/interfaces/businessgame';
import { FunTeamCluesPlayer, FunTeamCluesTeam, FunTeamCluesWord } from '@/interfaces/funteamclues';

import { GAME_NAMES } from '@/helpers';

export default function () {
  const store = useStore();
  const db = firebase.firestore();
  const { push } = useRouter();
  const { infoToast } = useToast();
  const { t } = useI18n();

  const user = computed(() => store.state.user);

  const getCurrentCompany = async (companyId: string | string[] | null = null) => {
    let company;

    if (companyId) {
      company = await db
        .doc(`companies/${companyId}`)
        .get()
        .then(doc => {
          return { id: doc.id, ...doc.data() };
        });
    } else {
      company = await db
        .collection('companies')
        .where('userEmails', 'array-contains', user.value?.email)
        .get()
        .then((querySnapshot: any) => {
          const resultArray: any = [];

          querySnapshot.forEach((doc: any) => {
            resultArray.push({ id: doc.id, ...doc.data() });
          });

          return resultArray[0];
        });
    }

    document.documentElement.style.setProperty('--color-accent', `${company.brandColor}`);

    store.commit('setCompany', company);
  };

  const getOneFunTeamCluesGameAndListen = async (gameId: string) => {
    try {
      await db
        .collection(`companies/${store.state.company.id}/funTeamCluesGames`)
        .doc(gameId)
        .onSnapshot(doc => {
          store.commit('setCurrentFunTeamCluesGame', {
            ...doc.data(),
            id: doc.id
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getOneFunTeamCluesGame = async (gameId: string) => {
    return await db
      .collection(`companies/${store.state.company.id}/funTeamCluesGames`)
      .doc(gameId)
      .get()
      .then(doc => {
        return { id: doc.id, ...doc.data() };
      });
  };

  const getWords = async (language: string) => {
    return await db
      .doc(`funTeamCluesWords/${language}`)
      .get()
      .then(doc => {
        return doc.data()?.words;
      });
  };

  const updateGame = async (game: any) => {
    const docRef = await db
      .collection(`companies/${store.state.company.id}/funTeamCluesGames`)
      .doc(game.id);

    return await docRef
      .update({
        ...game,
        updatedOn: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        return docRef.get();
      })
      .then(doc => {
        return { id: doc.id, ...doc.data() };
      });
  };

  const playerHasJoinedTheGame: ComputedRef<boolean> = computed(() => {
    return (
      store.state.currentFunTeamCluesGame.players.find(
        (player: Player) => player.id === user.value?.id
      ) || store.state.currentFunTeamCluesGame.gameCreator?.id === user.value?.id
    );
  });

  const loggedInUserIsAPlayer: ComputedRef<boolean> = computed(() => {
    return store.state.currentFunTeamCluesGame.players.some(
      (player: FunTeamCluesPlayer) => player.id === user.value?.id
    );
  });

  const showJoinGame: ComputedRef<boolean> = computed(() => {
    if (!user.value?.id) {
      return true;
    }

    if (store.state.currentFunTeamCluesGame.gameCreator?.id === user.value?.id) {
      return false;
    }

    return Boolean(
      store.state.currentFunTeamCluesGame &&
        store.state.currentFunTeamCluesGame.players &&
        user.value?.id &&
        !playerHasJoinedTheGame.value
    );
  });

  const showStartGameButton: ComputedRef<boolean> = computed(
    () =>
      store.state.currentFunTeamCluesGame &&
      !store.state.currentFunTeamCluesGame.gameStarted &&
      !store.state.currentFunTeamCluesGame.gameCancelled &&
      store.state.currentFunTeamCluesGame.players &&
      store.state.currentFunTeamCluesGame.players.length > 1 &&
      user &&
      store.state.currentFunTeamCluesGame.players.find((player: FunTeamCluesPlayer) => player.admin)
        .id === user.value?.id &&
      store.state.currentFunTeamCluesGame.teams.filter((t: FunTeamCluesTeam) =>
        t.players.find(player => player.isSpy)
      ).length === 2 &&
      store.state.currentFunTeamCluesGame.teams.filter((t: FunTeamCluesTeam) =>
        t.players.find(player => !player.isSpy)
      ).length === 2
  );

  const wordsLeft: ComputedRef<any> = computed(() => {
    return {
      BLUE: store.state.currentFunTeamCluesGame.words.filter(
        (w: FunTeamCluesWord) => w.belongsTo === 'BLUE' && !w.selected
      ).length,
      RED: store.state.currentFunTeamCluesGame.words.filter(
        (w: FunTeamCluesWord) => w.belongsTo === 'RED' && !w.selected
      ).length
    };
  });

  const currentPlayer: ComputedRef<FunTeamCluesPlayer> = computed(() => {
    return store.state.currentFunTeamCluesGame.players.find(
      (player: FunTeamCluesPlayer) => player.id === user.value?.id
    );
  });

  const currentPlayerInTeam: ComputedRef<FunTeamCluesPlayer> = computed(() => {
    const allPlayers = store.state.currentFunTeamCluesGame.teams.flatMap(
      (team: FunTeamCluesTeam) => team.players
    );

    return allPlayers.find((player: FunTeamCluesPlayer) => player.id === user.value?.id);
  });

  const spyFromCurrentTeam: ComputedRef<FunTeamCluesPlayer> = computed(() => {
    const currentTeam = store.state.currentFunTeamCluesGame.teams.find(
      (t: FunTeamCluesTeam) => t.name === store.state.currentFunTeamCluesGame.activeTeamName
    );

    if (currentTeam) {
      return currentTeam.players.find((player: FunTeamCluesPlayer) => player.isSpy);
    } else {
      return '';
    }
  });

  const joinTeam = async (team: FunTeamCluesTeam) => {
    const teamIndex = store.state.currentFunTeamCluesGame.teams.findIndex(
      (t: FunTeamCluesTeam) => t.id === team.id
    );

    await updateGame({
      id: store.state.currentFunTeamCluesGame.id,
      teams: [
        ...store.state.currentFunTeamCluesGame.teams.slice(0, teamIndex),
        {
          ...team,
          players: [...team.players, { ...currentPlayer.value, team: team.name }]
        },
        ...store.state.currentFunTeamCluesGame.teams.slice(teamIndex + 1)
      ],
      players: store.state.currentFunTeamCluesGame.players.map((p: FunTeamCluesPlayer) => ({
        ...p,
        team: p.id === currentPlayer.value.id ? team.name : p.team
      }))
    });
  };

  const leaveTeam = async (team: FunTeamCluesTeam) => {
    const teamIndex = store.state.currentFunTeamCluesGame.teams.findIndex(
      (t: FunTeamCluesTeam) => t.id === team.id
    );

    const filteredPlayers = team.players.filter(p => p.id !== currentPlayer.value.id);

    const updatedTeam = {
      ...team,
      players: filteredPlayers
    };

    await updateGame({
      id: store.state.currentFunTeamCluesGame.id,
      teams: [
        ...store.state.currentFunTeamCluesGame.teams.slice(0, teamIndex),
        updatedTeam,
        ...store.state.currentFunTeamCluesGame.teams.slice(teamIndex + 1)
      ],
      players: store.state.currentFunTeamCluesGame.players.map((p: FunTeamCluesPlayer) => ({
        ...p,
        team: p.id === currentPlayer.value.id ? '' : p.team
      }))
    });
  };

  const changeTeamName = async (teams: FunTeamCluesTeam[], teamId: string, newName: string) => {
    const teamIndex = teams.findIndex(t => t.id === teamId);
    const team = teams.find(t => t.id === teamId) || { players: [] };

    await updateGame({
      id: store.state.currentFunTeamCluesGame.id,
      teams: [
        ...store.state.currentFunTeamCluesGame.teams.slice(0, teamIndex),
        {
          ...team,
          customName: newName
        },
        ...store.state.currentFunTeamCluesGame.teams.slice(teamIndex + 1)
      ]
    });
  };

  const changeSpyStatus = async (
    player: FunTeamCluesPlayer,
    team: FunTeamCluesTeam,
    removeSpy: boolean
  ) => {
    const teamIndex: number = store.state.currentFunTeamCluesGame.teams.findIndex(
      (t: FunTeamCluesTeam) => t.id === team.id
    );

    await updateGame({
      id: store.state.currentFunTeamCluesGame.id,
      teams: [
        ...store.state.currentFunTeamCluesGame.teams.slice(0, teamIndex),
        {
          ...team,
          players: team?.players.map((p: FunTeamCluesPlayer) => ({
            ...p,
            isSpy: removeSpy ? false : p.id === player.id
          }))
        },
        ...store.state.currentFunTeamCluesGame.teams.slice(teamIndex + 1)
      ],
      players: store.state.currentFunTeamCluesGame.players.map((p: FunTeamCluesPlayer) => ({
        ...p,
        isSpy: removeSpy ? false : p.id === player.id
      }))
    });
  };

  const joinGame = async (payload: {
    name: string;
    nickname: string;
    job: string;
    avatar: Avatar;
  }) => {
    if (!payload.name) {
      return;
    }
    if (!user.value?.id) {
      await firebase.auth().signInAnonymously();
    }
    await updateGame({
      id: store.state.currentFunTeamCluesGame.id,
      players: firebase.firestore.FieldValue.arrayUnion(
        ...[
          {
            ...emptyFunTeamCluesPlayer,
            id: user.value?.id,
            email: user.value?.email,
            admin: !store.state.currentFunTeamCluesGame.players.length,
            ...payload
          }
        ]
      )
    });

    if (!store.state.currentFunTeamCluesGame.players.length) {
      infoToast(t('newGame.firstPlayerToBeAdmin'), 6000);
      store.commit('toggleSound', true);
    }
  };

  const startGame = async () => {
    await updateGame({
      id: store.state.currentFunTeamCluesGame.id,
      gameStarted: true,
      phase: 'GIVE_CLUE'
    });
  };

  const cancelGame = async () => {
    try {
      await db.doc(`companies/${store.state.company.id}`).update({
        package: {
          ...store.state.company.package,
          gamesLeft: {
            ...store.state.company.package.gamesLeft,
            [GAME_NAMES.FUN_TEAM_CLUES]:
              store.state.company.package.gamesLeft[GAME_NAMES.FUN_TEAM_CLUES] + 1
          }
        }
      });

      await db
        .collection(`companies/${store.state.company.id}/funTeamCluesGames`)
        .doc(store.state.currentFunTeamCluesGame.id)
        .update({
          gameCancelled: true
        });
    } catch (e) {
      console.log(e);
    }

    push('/dashboard');
  };

  const endGame = async (winningTeamName: string) => {
    await updateGame({
      id: store.state.currentFunTeamCluesGame.id,
      gameEnded: true,
      endResults: {
        winningTeamName: store.state.currentFunTeamCluesGame.teams.find(
          (t: FunTeamCluesTeam) => t.name === winningTeamName
        )
      },
      clue: '',
      clueNumberOfGuesses: 0
    });
  };

  const checkWhichTeamWon = (words: FunTeamCluesWord[]) => {
    const blueWordsLeft = words.filter(
      (w: FunTeamCluesWord) => w.belongsTo === 'BLUE' && !w.selected
    ).length;
    const redWordsLeft = words.filter(
      (w: FunTeamCluesWord) => w.belongsTo === 'RED' && !w.selected
    ).length;

    if (!blueWordsLeft) {
      return 'BLUE';
    } else if (!redWordsLeft) {
      return 'RED';
    } else {
      return false;
    }
  };

  const giveClue = async (clue: string, clueNumberOfGuesses: number) => {
    await updateGame({
      id: store.state.currentFunTeamCluesGame.id,
      phase: 'GUESSING',
      clue,
      clueNumberOfGuesses,
      log: firebase.firestore.FieldValue.arrayUnion(
        ...[
          {
            translation: 'funTeamClues.log.gaveClue',
            data: {
              player: spyFromCurrentTeam.value.name,
              team: spyFromCurrentTeam.value.team,
              value: clue.toUpperCase()
            }
          }
        ]
      )
    });
  };

  const guessWord = async (word: FunTeamCluesWord) => {
    const endOfRound = store.state.currentFunTeamCluesGame.clueNumberOfGuesses - 1 === 0;
    const activeTeamName = store.state.currentFunTeamCluesGame.activeTeamName;
    const updatedWords = store.state.currentFunTeamCluesGame.words.map((w: FunTeamCluesWord) => ({
      ...w,
      selected: w.id === word.id ? true : w.selected
    }));

    const winningTeamName = checkWhichTeamWon(updatedWords);

    await updateGame({
      id: store.state.currentFunTeamCluesGame.id,
      words: updatedWords,
      clueNumberOfGuesses: store.state.currentFunTeamCluesGame.clueNumberOfGuesses - 1,
      clue: endOfRound ? '' : store.state.currentFunTeamCluesGame.clue,
      lastClickedWord: word,
      log: firebase.firestore.FieldValue.arrayUnion(
        ...[
          {
            translation: 'funTeamClues.log.guessed',
            data: {
              player: currentPlayerInTeam.value.name,
              team: currentPlayerInTeam.value.team,
              wordBelongsTo: word.belongsTo,
              value: word.label.toUpperCase()
            }
          }
        ]
      )
    });

    if (word.belongsTo === 'KILLER') {
      await endGame(activeTeamName === 'RED' ? 'BLUE' : 'RED');
    } else {
      await updateGame({
        id: store.state.currentFunTeamCluesGame.id,
        phase: endOfRound ? 'GIVE_CLUE' : 'GUESSING',
        activeTeamName:
          endOfRound && !winningTeamName
            ? activeTeamName === 'RED'
              ? 'BLUE'
              : 'RED'
            : activeTeamName
      });
    }

    if (winningTeamName) {
      await endGame(winningTeamName);
    }
  };

  return {
    showJoinGame,
    showStartGameButton,
    currentPlayer,
    playerHasJoinedTheGame,
    loggedInUserIsAPlayer,
    currentPlayerInTeam,
    wordsLeft,
    spyFromCurrentTeam,
    startGame,
    joinGame,
    getOneFunTeamCluesGame,
    updateGame,
    getOneFunTeamCluesGameAndListen,
    getCurrentCompany,
    endGame,
    cancelGame,
    getWords,
    joinTeam,
    leaveTeam,
    changeTeamName,
    changeSpyStatus,
    giveClue,
    guessWord,
    checkWhichTeamWon
  };
}
