<template>
  <div class="flex items-center funteamclues__player">
    <div class="flex items-center justify-between funteamclues__player-info">
      <div class="funteamclues__player-name-wrapper">
        <div class="flex items-center">
          <div class="funteamclues__player-name">
            {{ player.name }}
            <Chip v-if="player.admin" class="small">
              {{ player.admin ? `${$t('players.admin')}` : '' }}
            </Chip>
            <Chip v-if="player.id === currentPlayer.id" class="small primary ml1">
              {{ player.id === currentPlayer.id ? `${$t('players.you')}` : '' }}
            </Chip>
          </div>
          <div
            class="flex items-center justify-center funteamclues__player-become-spy-indicator ml1"
            v-if="showChangeSpy && player.id === currentPlayer.id"
            @click="$emit('change', player.id)"
          >
            <span class="become-spy">
              {{ player.isSpy ? $t('funTeamClues.becomeOperative') : $t('funTeamClues.becomeSpy') }}
            </span>
          </div>
          <div v-if="player.nickname" class="funteamclues__player-nickname">
            {{ player.nickname }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FunTeamCluesPlayer',
    props: {
      player: {
        type: Object,
        default: () => ({})
      },
      currentPlayer: {
        type: Object,
        default: () => ({})
      },
      showChangeSpy: {
        type: Boolean,
        default: true
      }
    },
    emits: ['change']
  };
</script>

<style scoped lang="scss">
  .funteamclues__player {
    margin: 4px 0;
    max-width: 300px;

    @include mobile() {
      .p-chip {
        display: none;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid color(gray-background);
    }

    &-info {
      width: 100%;
    }

    &-indicator {
      height: global-vars(ui-default-measure4x);
      border-radius: 50%;
    }

    &-become-spy-indicator {
      height: global-vars(ui-default-measure4x);
      cursor: pointer;

      .become-spy {
        @include font(10px, 600);
        text-align: center;
        color: black;
        padding: 2px 6px;
        border-radius: 12px;
        border: 1px solid black;
      }
    }

    &-name-wrapper {
      margin-right: 10px;
    }

    &-name {
      @include font(14px, 600);
      margin-bottom: 4px;

      @include mobile() {
        @include font(10px, 600);
      }
    }

    &-nickname {
      @include font(11px, 600);
      background: color(gray-background);
      color: color(primary);
      margin-left: global-vars(ui-default-measure);
      padding: 4px 8px;
      border-radius: 10px;
      margin-bottom: 4px;

      @include mobile() {
        padding: 3px 6px;
      }
    }
  }
</style>
