
  import { ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  import useFirebaseFunTeamClues from '@/composables/use-firebase-funteamclues';
  import {
    FunTeamCluesPlayer as PlayerInterface,
    FunTeamCluesTeam
  } from '@/interfaces/funteamclues';
  import BaseButtonWithSpinner from '@/components/common/BaseButtonWithSpinner.vue';
  import FunTeamCluesPlayer from '@/components/funteamclues/FunTeamCluesPlayer.vue';

  import useFirebase from '@/composables/use-firebase';
  import useToast from '@/composables/use-toast';

  export default {
    name: 'FunTeamCluesTeam',
    components: {
      BaseButtonWithSpinner,
      FunTeamCluesPlayer
    },
    props: {
      activeTeamName: {
        type: String,
        default: ''
      },
      phase: {
        type: String,
        default: ''
      },
      team: {
        type: Object,
        default: () => ({})
      },
      teams: {
        type: Array,
        default: () => []
      },
      players: {
        type: Array,
        default: () => []
      }
    },
    setup(props: any) {
      const { joinTeam, leaveTeam, currentPlayer, changeTeamName, changeSpyStatus, wordsLeft } =
        useFirebaseFunTeamClues();

      const { getCurrentUser } = useFirebase();
      const user = getCurrentUser();
      const { t } = useI18n();
      const { warningToast } = useToast();

      const activeTeamId = ref('');
      const newTeamNameLoading = ref(false);
      const showNewTeamNameModal = ref(false);
      const newTeamName = ref('');

      const openNewTeamModal = (id: string) => {
        showNewTeamNameModal.value = true;
        activeTeamId.value = id;
      };

      const handleChangeNewTeamName = async () => {
        if (!newTeamName.value) {
          warningToast(t('funTeamBidding.teams.emptyName'));
          return;
        }

        if (props.teams.some((t: FunTeamCluesTeam) => t.customName === newTeamName.value)) {
          warningToast(t('funTeamBidding.teams.nameTaken'));
          return;
        }

        newTeamNameLoading.value = true;
        await changeTeamName(props.teams, activeTeamId.value, newTeamName.value);
        newTeamNameLoading.value = false;
        showNewTeamNameModal.value = false;
      };

      const filteredPlayers = computed(() => ({
        notSpy: props.team.players.filter((p: PlayerInterface) => !p.isSpy),
        spy: props.team.players.find((p: PlayerInterface) => p.isSpy)
      }));

      const isPlayerInAnyTeam = computed(() => {
        const playersFromAllTeams = props.teams.flatMap((t: FunTeamCluesTeam) => t.players);

        return playersFromAllTeams.some((player: PlayerInterface) => player.id === user?.uid);
      });

      const loggedInUserIsAPlayer = computed(() => {
        return props.players.some((player: PlayerInterface) => player.id === user?.uid);
      });

      const showJoinTeam = computed(() => {
        if (!loggedInUserIsAPlayer.value) {
          return false;
        } else if (!isPlayerInAnyTeam.value) {
          return true;
        }

        return false;
      });

      const isPlayerInSpecificTeam = (team: FunTeamCluesTeam) => {
        return team?.players.some(
          (player: PlayerInterface) => player.id === currentPlayer.value?.id
        );
      };

      return {
        currentPlayer,
        isPlayerInAnyTeam,
        newTeamNameLoading,
        showNewTeamNameModal,
        newTeamName,
        showJoinTeam,
        filteredPlayers,
        wordsLeft,
        isPlayerInSpecificTeam,
        changeTeamName,
        joinTeam,
        leaveTeam,
        changeSpyStatus,
        openNewTeamModal,
        handleChangeNewTeamName
      };
    }
  };
